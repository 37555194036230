.signup-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;

  @include media('<sm') {
    padding: 0px 22px;
  }
  @media (max-width: 946px) {
    display: flex;
    justify-content: center;
  }

  .left-block {
    padding: 60px 70px 25px;
    width: auto;
    background-color: white;
    display: flex;
    justify-content: flex-end;

    @include media('<md-lg') {
      padding: 50px 0 0;
      width: inherit;
      justify-content: center;
    }
    @media (max-width: 1360px) {
      padding: 50px 40px 25px;
    }
    @include media('<sm') {
      padding: 25px 0;
      width: 100%;
    }

    .signup-form-container {
      width: 441px;

      @include media('<sm') {
        max-width: 441px;
        width: 100%;
      }

      h1 {
        margin-top: 17px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: $text-color;

        @include media('<sm') {
          font-size: 30px;
        }
      }
      .join-now-block {
        display: flex;
        margin: 7px 0 10px 0;
        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $text-color;
        }
        .join-now {
          text-decoration: none;
          font-family: $font-family-base;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: $brand-accent;
          margin-left: 4px;

          @include media('<sm') {
            margin-left: 20px;
          }
        }
      }
      .signup-form {
        position: relative;
        .error-message {
          font-size: 16px;
          color: red;
          position: absolute;
          top: 200px;
        }
      }
      .input-email, .input-name {
        padding-bottom: 35px;
      }
      .input-password {
        @include media('<md') {
          padding-bottom: 0px;
        }
      }
      .field {
        padding-bottom: 20px;
        &__label {
          font-size: 18px;
          font-weight: normal;
        }
        &__input {
          height: 45px;
          font-size: 18px;
        }
      }
      .remember-me {
        margin-top: 5px;
        .checkbox__title {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          @include media('<sm') {
            font-size: 14px;
          }
        }
        &.hide-on-tablet {
          @include media('<lg') {
            display: none;
          }
        }
      }
      .signup-button {
        width: 100%;
        height: 50px;
        font-size: 18px;
        line-height: 22px;
        margin: 20px 0;
        letter-spacing: 0.1em;

        @include media('<lg') {
          margin: 0 0 10px;
        }
        @include media('<sm') {
          margin: 20px 0;
        }
      }
      .social-btn {
        min-width: 103px;
        width: 141px;
        // background-color: #F5F5F5;
        @media (max-width: 480px) {
          width: 120px;
        }
        @media (max-width: 410px) {
          width: 103px;
        }
      }
      .switch-vera-type {
        text-align: center;
        margin-top: 40px;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        span {
          color: $brand-accent;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .mobile-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text-wrapper {
          max-width: 271px;
          .currency {
            font-weight: bold;
            line-height: 29px;
            color: $brand-accent;
            margin: 9px 0 17px;
            font-size: 38px;
            text-align: center;
          }
          h1 {
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            line-height: 29px;
          }
          p {
            font-size: 14px;
            line-height: 29px;
            text-align: center;
            margin: 10px 0;
          }
          .thesises-wrapper {
            .thesis {
              display: flex;
              font-size: 14px;
              line-height: 32px;
              span {
                padding: 0;
              }
              svg {
                margin-top: 10px;
              }
            }
          }

          &.listener {
            margin-bottom: 20px;

            p {
              font-size: 12px;
              line-height: 16px;
              margin: 20px 0;
            }
          }
        }
        .image {
          margin-top: 50px;
          max-width: 125px;

          &.listener {
            max-width: 195px;
          }
        }
      }
    }
  }
  .right-block {
    width: 100%;
    padding: 100px 70px 0 90px;
    position: relative;
    background-color: white;

    .text-wrapper {
      width: 100%;
      max-width: 581px;
      padding: 82px 0 90px 93px;
      background: #FBF8FF;
      border: 2px solid #D9BCFC;
      box-sizing: border-box;
      border-radius: 73px;
      position: relative;

      @include media('<xl') {
        max-width: 384px;
        padding: 60px 0 90px 67px;
      }
      @include media('<lg') {
        max-width: 398px;
      }

      .currency {
        font-weight: bold;
        font-size: 50px;
        line-height: 29px;
        color: $brand-accent;
        margin: 37px 0 18px;

        @include media('<xl') {
          margin: 17px 0 18px;
          font-size: 42px;
        }
        @include media('<sm') {
        }
      }

      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 29px;
        color: #263238;

        @include media('<xl') {
          font-size: 32px;
        }
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        max-width: 411px;
        font-size: 18px;
        margin: 10px 0 15px;
        line-height: 29px;
        color: #263238;
        width: 100%;

        @include media('<xl') {
          font-size: 18px;
        }
        @include media('<lg') {
          max-width: 275px;
        }
      }
      .thesises-wrapper {
        .thesis {
          display: flex;
          line-height: 38px;

          @include media('<lg') {
            max-width: 290px;
            width: 100%;
          }
          span {
            font-size: 18px;
            padding: 0;
            &.last-thesis {
              @include media('<lg') {
                width: 260px;
              }
            }
          }
          svg {
            margin-top: 10px;
          }
        }
      }

      &.listener {
        padding-bottom: 72px;

        h1 {
          font-size: 20px;
          line-height: 29px;
        }
        p {
          margin-bottom: 40px;
          @include media('<xl') {
            line-height: 18px;
            font-size: 14px;
            max-width: 100%;
            margin-bottom: 13px;
          }
        }

        .thesises-wrapper {
          .thesis {
            span {
              font-size: 14px;
              line-height: 35px;
            }
          }
        }

        @include media('<xl') {
          max-width: 400px;
          padding: 60px 0 100px 50px;
        }
      }
    }
    .image {
      max-width: 230px;
      width: 100%;
      height: auto;
      position: absolute;
      top: 55%;
      left: 80%;

      @include media('<lg') {
        max-width: 158px;
        top: 65%;
        left: 65%;
      }

      &.listener {
        max-width: 320px;
        top: 60%;
        left: 70%;

        @include media('<lg') {
          max-width: 217px;
          top: 74%;
          left: 60%;
        }
      }
    }

    @include media('<xl') {
      padding: 100px 47px;
    }
    @include media('<lg') {
      padding-left: 17px;
    }
    @media (max-width: 1360px) {
      padding-left: 15px;
    }
    @media (max-width: 946px) {
      display: none;
    }

    .billing-text {
      font-size: 14px;
      line-height: 23px;
      max-width: 340px;
      width: 100%;
      margin: 28px 0 0 65px;

      @include media('<xl') {
        margin: 24px 0 0;
        max-width: 224px;
        font-size: 12px;
      }
    }

    &.listener {
      padding-top: 90px;
    }
  }

  // fast update for errors
  .field__error {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 17px;
  }
}