.react-select-container {
  max-width: 481px;
}
.react-select {
  $this: &;

  &__clear-indicator {
    display: none!important;
  }
  &__search-icon {
    position: absolute;
    left: 17px;
  }
  &__control {
    border-color: $black;
    border-radius: 30px;

    &--is-focused {
      #{$this}__placeholder {
        display: none;
      }
    }
  }
}
.songs-filter__select {
  padding-bottom: 0;

  .react-select{

    &__control {
      border-color: $black;
      border-radius: 30px;
    }
    &__dropdown-indicator {
      color: $black;
    }
    &__option {
      font-size: 16px;
      line-height: 20px;
    }
    &__single-value {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.profiles-filter__select {
  padding-bottom: 0;

  .react-select {

    &__control {
      border-color: $black;
      border-radius: 4px;
      height: 34px;
    }
    &__value-container {
      height: 32px;
      padding-left: 15px;
    }
    &__dropdown-indicator {
      color: $black;
    }
    &__option {
      font-size: 16px;
      line-height: 20px;
    }
    &__single-value {
      font-size: 14px;
      line-height: 17px;
    }
  }
}