#root {
  position: relative;
  height: 100%;
}
.app {
  position: relative;
  height: 100%;
  padding: 17px;
  background-color: #F4F5F8;

  @include media('<md') {
    padding: 0px 17px 17px;
    min-height: 100%;
  }
}
.guest-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;

  @include media('<sm') {
    height: auto;
    min-height: 100vh;
  }
}

.layout {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  @include media('<md') {
    display: block;
    height: calc(100% + 17px);
  }

  &__aside {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 290px;
    box-sizing: border-box;
    padding-right: 17px;

    @include media('<xl') {
      flex-basis: 246px;
    }
    @include media('<lg') {
      flex-basis: 207px;
    }
    @include media('<md') {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 60px;
      margin: 0 -16px;
      padding-right: 0;
      z-index: 10;
    }
  }

  &__main {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 290px);
    width: calc(100% - 290px);
    position: relative;
    height: 100%;

    @include media('<xl') {
      flex-basis: calc(100% - 246px);
      width: calc(100% - 246px);
    }
    @include media('<md') {
      padding-bottom: 70px;
      flex-basis: 100%;
      width: 100%;
    }
  }
}
.layout-container {
  position: relative;
  height: 100%;
}

.nav-bar {
  background-color: $light;
  border-radius: 10px;
  min-height: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__logo {
    margin: 16px 0 48px 26px;
    display: block;

    @include media('<md') {
      display: none;
    }
  }

  @include media('<md') {
    padding: 6px 12px;
  }

  .vera-logo {
    @include media('<xl') {
      max-width: 100%;
      max-height: 22px;
    }
  }
}

.main-menu {
  $this: &;
  margin: 0 -16px;

  &__list {
    margin: 0;

    @include media('<md') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__list-item {
    padding: 0;
    margin: 0;

    @include media('<md') {
      width: 20%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &--active {}

    &--resident {
      padding: 0 0 0 27px;

      @include media('<md') {
        display: none;
      }
    }
  }
  &__link {
    position: relative;
    padding: 0 4px 0 27px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: $dark;
    text-decoration: none;
    display: block;
    width: 100%;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 100%;
      right: 0;
      top: 0;

      @include media('<md') {
        display: none;
      }
    }

    &:hover {
      #{$this}__link-container {
        background-color: $navbar-hover;
      }
    }

    &--active {
      color: $brand-color;
      background-image: $brand-color-gradient;

      #{$this}__icon {
        fill: $brand-color;
      }

      &::after {
        background-color: $brand-accent;
      }

      &.main-menu__link--relax {
        background-image: $relax-gradient;
        color: $dark;

        &::after {
          background-color: $relax;
        }
      }
      &.main-menu__link--reminisce {
        background-image: $reminisce-gradient;
        color: $dark;

        &::after {
          background-color: $reminisce;
        }
      }
      &.main-menu__link--energise {
        background-image: $energise-gradient;
        color: $dark;

        &::after {
          background-color: $energise;
        }
      }

      &:hover {
        #{$this}__link-container {
          background-color: transparent;
        }
      }

      @include media('<md') {
        background-image: none;

        &:hover {
          #{$this}__link-container {
            background-color: $navbar-hover;
          }
        }

        #{$this}__link-container {
          background-color: $navbar-hover;
        }
      }
    }
    @include media('<md') {
      padding: 0;
      text-transform: none;
    }
    &--inactive {
      &:hover {
        #{$this}__link-container {
          background-color: transparent;
        }
      }
    }
    &--disabled {
      color: #A1A1A1;
    }
  }
  &__link-container {
    padding: 12px 0 12px 15px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media('<md') {
      flex-direction: column;
      padding: 5px 5px;
      border-radius: 4px;
    }
  }
  &__icon {
    font-size: 16px;
    margin-right: 11px;

    @include media('<md') {
      margin-right: 0;
      margin-bottom: 2px;
    }
  }
  &__sub-list {
    margin: 0 0 0 0;

    @include media('<md') {
      display: none;
    }
  }
  &__sub-item {
    padding: 0;
    margin: 0;

    #{$this}__link {
      padding-left: 54px;
      text-transform: none;
      width: 100%;
    }
  }

  &__close-chat {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 0;
    width: 100%;
    background-color: $brand-accent;
    height: 100%;
    margin: 0;
    padding: 6px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__close-chat-text {
    font-size: 20px;
    line-height: 24px;
    color: $light;
  }
  &__close-chat-icon {
    margin-right: 10px;
    font-size: 25px;

    & path {
      fill: $light;
    }
  }

  @include media('<md') {
    margin: 0;
  }

  &__button {
    cursor: pointer;
    width: calc(100% - 26px);
    margin: 20px 15px 20px 11px;
    border-radius: 4px;
    background-color: $brand-accent;
    padding: 14px 14px 13px 28px;
    color: $light;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<md') {
      margin: 0;
      width: 100%;
      background-color: transparent;
      position: relative;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      font-weight: 600;
      color: $dark;
      text-decoration: none;
      display: block;
    }
  }
  &__button-mode {
    @include media('<md') {
      display: none;
    }
  }
  &__button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include media('<md') {
      flex-direction: column;
      padding: 5px 5px;
      border-radius: 4px;
    }
  }
  &__button-icon {
    margin-right: 5px;
    fill: $light;

    @include media('<md') {
      margin-right: 0;
      margin-bottom: 2px;
      font-size: 16px;
      fill: $text-color;
    }
  }
  &__button-text {}
}

.music-type-label {
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 700;
  color: $light;
  background-color: $brand-accent;
  border-radius: 20px;
  padding: 1px 11px 0;

  @include media('<xl') {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 10px 0;
  }

  &--relax {
    background-color: $relax;
  }
  &--reminisce {
    background-color: $reminisce;
  }
  &--energise {
    background-color: $energise;
  }

  @include media('<md') {
    font-size: 12px;
    line-height: 18px;
  }
}
.layout-main-container {
  position: relative;
  height: 100%;
  padding-top: 331px;
  box-sizing: border-box;

  @include media('<xl') {
    padding-top: 309px;
  }

  @include media('<md') {
    padding-top: 202px;
  }

  &--small-player {
    padding-top: 164px;

    @include media('<xl') {
      padding-top: 152px;
    }

    @include media('<md') {
      padding-top: 90px;
    }
  }

  &--without-small-player {
    padding-top: 0;
  }
}
.music-player-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 14px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  @include media('<md') {
    margin-top: 16px;
  }
}
.greeting-banner-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
.greeting-banner {
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 0.9);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 60px 40px 89px;
  flex-basis: 100%;
  border-radius: inherit;
  border-radius: 10px;

  @include media('<md') {
    padding: 27px 27px 21px;
  }
  @include media('<sm') {
    padding: 21px 21px;
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__title {
    font-size: 42px;
    line-height: 51px;
    font-weight: 700;
    color: $light;

    @include media('<md') {
      font-size: 30px;
      line-height: 36px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 27px;
    color: $light;

    &.banner-home {
      max-width: 380px;
    }

    @include media('<md') {
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__buttons {
    margin-top: 30px;

    @include media('<md') {
      margin-top: 15px;
    }
  }
  &__button {

    @include media('<sm') {
      font-size: 13px;
      padding: 10px 15px;
    }
  }

  &__image-container {
    flex-basis: 290px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media('<md') {
      display: none;
    }
  }
}
.scroll-container {
  margin: -14px -17px -17px;
  padding: 14px 17px 17px;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  height: calc(100% + 31px);
  box-sizing: border-box;
  overflow-y: scroll;

  @include media('<md') {
    margin-top: 0;
    height: calc(100% + 14px);
  }
}
.content-block {
  position: relative;
  background-color: $light;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 17px;

  @include media('<md') {
    padding: 12px 27px 27px;
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
    padding: 14px 0 0 0;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  hr {

    @include media('<md') {
      margin: 0 -27px 17px;
      width: auto;
    }
  }

  &__title-container {
    @include media('<md') {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 7px;
    }
    .btn-back {
      @include media('<md') {
        margin-bottom: 0;
      }
    }
    .btn {
      margin-bottom: 0;

      @include media('<md') {
        margin-bottom: 0;
      }
    }
  }
}
.title-container {
  min-height: 45px;
  margin-bottom: 14px;
}
.preferences-navigation {
  margin-bottom: 24px;

  @include media('<md') {
    margin-bottom: 7px;
  }


  &__row {
    margin: 0 -4px;
  }
  &__col {
    padding: 0 4px;
  }
}
.info-zone {
  position: relative;
  margin-bottom: 25px;

  &__pro-wrapper {

    .membership-pro-header {
      font-family: $font-family-base;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }

    .membership-pro-text {
      font-family: $font-family-base;
      font-size: 16px;
      line-height: 22px;

      &--small {
        font-size: 14px;
        margin: 5px 0 24px;
      }
    }

    .membership-pro-row {
      display: flex;
      width: 250px;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }

  &__membership-title {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #8F8F8F;
  }

  &__membership-details {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1D1D1D;
    margin-top: 10px;

  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-transform: uppercase;
    color: #8F8F8F;
  }
  &__edit-button {
    margin-left: 8px;
    padding: 3px 8px;
    color: $brand-accent;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__edit-button-icon {
    font-size: 12px;
    margin-right: 5px;
  }

  &--form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &--guest-admin {
    padding-top: 10px;
    margin-bottom: 64px;
  }
}
.info-field {
  $this: &;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  @include media('<md') {
    align-items: flex-start;
  }

  &__label {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 167px;
    padding-right: 10px;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media('<md') {
      font-size: 16px;
      line-height: 20px;
      flex-basis: 127px;
    }
  }
  &__label-image {
    margin-right: 13px;
  }
  &__value {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: calc(100% - 167px);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media('<md') {
      flex-direction: column;
      align-items: flex-start;
      word-break: break-word;
    }
  }
  &__value-text {
    font-size: 20px;
    line-height: 24px;

    @include media('<md') {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &--photo {
    align-items: flex-start;
  }

  &--vertical {
    flex-direction: column;
    align-items: flex-start;

    #{$this}__label {
      flex-basis: 100%;
      padding-right: 0;
      margin-bottom: 8px;
    }
  }
  &--card-details {
    margin-bottom: 50px;
  }
  &--wide {
    #{$this}__label {
      flex-basis: 267px;

      @include media('<md') {
        flex-basis: 127px;
      }
    }
    #{$this}__value {
      flex-basis: calc(100% - 267px);

      @include media('<md') {
        flex-basis: calc(100% - 127px);
      }
    }
  }

  .info-zone__edit-button {
    @include media('<md') {
      margin-left: 0px;
      padding: 3px 0px;
    }
  }
}

.change-password-form {
  width: 100%;
  max-width: 513px;
}

.mobile-submenu {
  list-style: none;

  &__list {
    margin: 0;
  }
  &__list-item {
    margin: 0 0 10px;
    padding: 0;
  }
}
.mobile-submenu-item {
  background-color: $light;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  padding: 20px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  text-align: left;
  width: 100%;

  &__data {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: calc(100% - 70px);
  }
  &__icon-container {
    &--play {
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 70px;
      padding-left: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__icon-play {
    font-size: 39px;
    margin-bottom: 4px;
  }
  &__icon-description {
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    text-align: center;

    &--relax {
      color: $relax;
    }
    &--reminisce {
      color: $reminisce;
    }
    &--energise {
      color: $energise;
    }
  }
  &__icon-arrow-right {
    font-size: 8px;
    fill: $gray-text-color2;
  }
  &__title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: $dark;
  }
  &__description {
    font-size: 14px;
    line-height: 22px;
    color: $dark;
  }
}
.logout-container {
  max-width: 171px;
  margin: 0 auto;
  position: relative;
}

.vera-logo {
  @include media('<sm') {
    width: 87px;
  }

  &--pro {
    @include media('<sm') {
      width: 134px;
    }
  }
}

.btn-chat {
  $this: &;
  background-color: #F8F1FF;
  border: 1px solid #B1B1B1;
  border-radius: 10px;
  padding: 13px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__icon-container {
    flex-basis: 48px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
  }
  &__icon {
    font-size: 29px;
    fill: $brand-accent;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }
  &__title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: #000;

    @include media('<xl') {
      font-size: 16px;
    }
  }
  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3F3F3F;
  }

  &--open {
    background-color: $brand-accent;
    border-color: $brand-accent;
    z-index: 999;

    #{$this}__title {
      color: $light;
    }
  }

  &--membership {
    position: absolute;
    bottom: 2.5%;
    left: 2%;
    width: 250px;
  }

  @include media('<md') {
    display: none;
  }
}

.genres-list {

  &__item {
    width: 214px;

    @include media('<md') {
      width: 50%;
    }
  }
}

.greeting-block {
  position: relative;
  margin-bottom: 11px;

  h1 {
    font-size: 30px;
    line-height: 36px;
  }
}

.dashboard-block {

  &__title {
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #929292;
    margin-bottom: 6px;
  }
}

.music-types-block {}

.videos-block {
  margin-bottom: 14px;
}

.full-height-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.videos-block {}

.btn-chat-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: -17px;
  left: -17px;
  z-index: 99;
  background-color: rgba(51, 51, 51, 0.9);
}

.resident-menu-card {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;

  &__photo-container {
    width: 46px;
    height: 46px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 14px;
    border-radius: 2px;
    overflow: hidden;
  }
  &__photo-image {
    object-fit: cover;
  }

  &__data {
    width: calc(100% - 60px);
  }
  &__name {
    font-size: 16px;
    line-height: 22px;
    color: $dark;
    font-weight: bold;
    margin-bottom: 3px;
  }
}

.active-resident-card {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  background-color: $player-bg;
  margin-bottom: 12px;

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 14px;
    color: #828282;
    padding-left: 19px;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 17px;
    padding: 0 19px;
  }

  &__photo-container {
    width: 55px;
    height: 55px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 11px;
  }
  &__photo-image {
    object-fit: cover;
  }

  &__data {
    width: calc(100% - 66px);
  }
  &__name {
    font-size: 16px;
    line-height: 22px;
    color: $light;
    font-weight: bold;
    margin-bottom: 2px;
  }
  &__id-container {
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    color: #828282;
  }
  &__id {
    font-weight: 400;
  }
  &__btn {
    margin-bottom: 0px;
  }
}