.react-datepicker-popper {
  z-index: 1;

  &--year {
    .react-datepicker {
      padding: 0px 37px 10px;
    }
  }
}
.react-datepicker-year-header {
  display: none;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}
.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}
.react-datepicker {
  padding: 0px;
  background-color: $light;
  border: 1px solid $gray-light;
  border-radius: $border-radius;

  &__navigation {
    position: absolute;
    width: 42px;
    height: 100%;
    top: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 11px;
    fill: $gray-text-color2;

    &--previous {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &__year{
    position: relative;

    &--container {}
  }
  &__year-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 258px;
  }
  &__year-text {
    padding: 15px 10px 0px;
    box-sizing: border-box;
    flex-basis: 63px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    color: $black;
    font-weight: 400;

    &:hover, &--selected {
      color: $brand-accent;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      text-decoration: underline;
    }

    &--disabled {
      color: $disabled;
      cursor: default;

      &:hover {
        color: $disabled;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
  &__month-text {
    font-size: 20px;
    line-height: 24px;
    color: $black;
    font-weight: 400;
    padding: 10px 22px;
    cursor: pointer;
    border-bottom: 1px solid #CFCFCF;

    &:hover {
      color: $brand-accent;
    }
  }
  &__month {
    &--selected {
      color: $brand-accent;
    }
  }
  &__month-wrapper {

    &:last-child {
      .react-datepicker__month-text {

        &:last-child {
          border: none;
        }
      }
    }
  }
  &__monthPicker {
    max-height: 300px;
    overflow-y: scroll;
  }
}

.field-date {

  .css-5tvghe-singleValue {
    font-weight: 600;
  }
  &__input-container {
    position: relative;
  }
  &__input {
    padding-right: 30px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    background-color: transparent;
  }
  &__arrow {
    position: absolute;
    font-size: 11px;
    fill: $gray-text-color2;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.field-date-input {
  width: 86px;
}
.field-month-input {
  width: 148px;
}
.field-year-input {
  width: 111px;

  &--full-width {
    width: 100%;
  }
}
.react-datepicker-ignore-onclickoutside {
  + .field-date__arrow {
    fill: $dark;
  }
}

.react-datepicker-popper-background{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}