// grid breakpoints
$grid-breakpoints: ( xs:0px, sm: 576px, md: 768px, md-lg: 814px, iPad: 1024px, lg: 1025px, xl: 1200px);
// grid containers
$container-max-widths: (md: 1024px, lg: 1200px, xl: 1400px);

// grid columns
$grid-columns: 12 !default;
$gutter: 24px !default;
$gutter-xs: 24px !default;
// font
$font-family-base: ProximaNova;
$font-default: sans-serif;
$body-font-size: 14px;

$border-radius: 4px;

// color
//-----------------------------------------------------------------------------------
$black: #000000;
$dark: #1D1D1D;
$light: #ffffff;
$gray: #696969;
$checkbox-color: #393939;
$gray-light: #CFCFCF;
$gray-light2: #F3F4F6;
$text-color: #1D1D1D;
$gray-text-color: #808080;
$gray-text-color2: #939393;
$brand-color: #4B2473;
$brand-accent: #7A23D3;
$light-accent: #DFBFFF;
$disabled: #CACACA;
$error: #E80000;
$relax: #0BCD9F;
$reminisce: #F06669;
$energise: #FDC453;
$player-bg: #333333;
$navbar-hover: #F8F1FF;
$gray-border: #BDBDBD;

$brand-color-gradient: linear-gradient(90deg, rgba(211, 195, 228, 0.07), rgba(83, 20, 149, 0.27));
$relax-gradient: linear-gradient(90deg, rgba(174, 238, 223, 0.19), rgba(11, 205, 159, 0.57));
$reminisce-gradient: linear-gradient(90deg, rgba(249, 197, 198, 0.32), rgba(240, 102, 105, 0.6));
$energise-gradient: linear-gradient(90deg, rgba(254, 229, 180, 0.33), rgba(253, 196, 83, 0.62));
$box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);