@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: local('ProximaNova'), url('./ProximaNova-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: local('ProximaNova'), url('./ProximaNova-Light.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  src: local('ProximaNova'), url('./ProximaNova-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova'), url('./ProximaNova-Semibold.otf') format('opentype');
}