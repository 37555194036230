.welcomeToVera-wrapper {
  max-width: 1440px;
  width: 100%;
  // margin: auto;
  .welcomeToVera-header {
    padding: 70px 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .logo {
      width: 108px;
      height: 32px;
      background-color: blueviolet;
    }

    @include media('<xl') {
      padding: 50px 40px 0;
    }

    @include media('<sm') {
      padding: 36px 22px 0;
    }
  }

  .welcomeToVera-container {
    margin: 20px auto 0;
    max-width: 974px;

    @include media('<xl') {
      padding: 0px 50px;
    }
    @include media('<sm') {
      margin: 0;
      padding: 36px 22px 0;
    }

    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;

      @include media('<sm') {
        font-size: 30px;
      }
    }

    p {
      font-family: $font-family-base;
      font-style: normal;
      font-size: 20px;
      line-height: 32px;
      color: #263238;

      @include media('<sm') {
        font-size: 18px;
        margin: 0;
      }
    }

    .cards-container {
      margin-top: 39px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include media('<md-lg') {
        justify-content: center;
      }

      @include media('<sm') {
        margin-top: 20px;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 47px;
      max-width: 375px;

      @include media('<lg') {
        margin-top: 7px;
      }

      @include media('<md') {
        .btn {
          width: 100%;
        }
      }
    }
  }
}