.accordion {
  position: relative;

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }


}
.accordion-item {
  $this: &;
  position: relative;
  background-color: $light;
  border-radius: 10px;
  margin-bottom: 17px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding: 15px 24px;
  //transition: 0.3s;

  &:last-child {
    margin-bottom: 0;
  }

  &__term {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &--small {
      width: 32px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  &__term-text {
    flex-basis: calc(100% - 52px);
    flex-shrink: 1;
    display: block;

    @include media('<md') {
      flex-basis: calc(100% - 32px);
    }
  }
  &__term-title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: $text-color;
    display: block;
    text-align: initial;

    @include media('<md') {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__term-subtitle {
    color: $text-color;
    font-size: 14px;
    line-height: 18px;
    display: block;
    text-align: initial;
  }
  &__icon-container {
    flex-basis: 52px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;

    @include media('<md') {
      flex-basis: 32px;
      font-size: 10px;
    }
  }
  &__icon {
    transition: 0.3s;
    fill: $gray-text-color2;
  }

  &__definition {
    //transition: 0.3s;
    display: block;
    height: 0px;
    overflow: hidden;
  }
  &__definition-container {
    padding-top: 18px;
    padding-right: 43px;
    position: relative;

    @include media('<lg') {
      padding-right: 0;
    }
  }

  &--open {

    #{$this}__icon {
      transform: rotate(90deg);
    }

    #{$this}__definition {
      height: 100%;
    }
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
    margin: 0 -27px;
    border-top: 1px solid #CCCCCC;
    border-radius: 0;
    padding: 12px 27px;

    &:last-child {
      border-bottom: 1px solid #CCCCCC;
    }
  }
}