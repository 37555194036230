.tooltip {
  &--icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white;
    background-color: black;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  .tooltipContent {
    &--wrapper {
      max-width: 300px;
      width: 100%;
      height: 320px;
    }
    &--header {
      line-height: 27px;
      margin: 0;
    }
    &--text {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;

      a {
        text-decoration: none;
        color:#bd7cff;
      }
    }
  }
  .first-paragraph {
    display: inline-block;
    margin-top: 10px;
  }
  .second-paragraph {
    display: inline-block;
    margin-top: 30px;
    font-weight: 600;
  }
  .third-paragraph {
    display: inline-block;
    margin-top: 15px;
    font-weight: 600;
  }
  .__react_component_tooltip {
    border-radius: 10px;
    background-color: rgb(60, 66, 70);
    padding: 25px 0 0 25px;
    &.show {
      opacity: 1;
    }
    &.place-right {
      margin-left: 15px;
    }
    &.place-right::after {
      content: "";
      display: block;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 14px solid rgb(60, 66, 70);
      margin-top: -12px;
      left: -12px;
    }
    &.place-top {
      margin-bottom: 15px;
    }
    &.place-top::after {
      content: "";
      display: block;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 14px solid rgb(60, 66, 70);
      margin-left: -12px;
      bottom: -12px;
    }
    &.place-left {
      margin-right: 15px;
    }
    &.place-left::after {
      content: "";
      display: block;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 14px solid rgb(60, 66, 70);
      margin-top: -12px;
      right: -12px;
    }
  }
}