.thank-you-wrapper {
  display: flex;
  max-width: 1440px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .thank-you-header {
    width: 100%;
    max-width: 1440px;
    padding: 70px 0 0 60px;

    @include media('<xl') {
      padding: 50px 0 0 40px;
    }

    @include media('<sm') {
      padding: 36px 22px 0;
    }
  }
  .thank-you-container {
    display: flex;
    width: 100%;

    @include media('<iPad') {
      width: 100%;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .left-block {
      width: 44%;
      padding: 10px 60px;
  
      @include media('<lg') {
        max-width: 450px;
        width: 100%;
        padding: 157px 0 0 125px;
      }

      @include media('<iPad') {
        padding: 0;
        align-items: center;
        flex-direction: column;
      }

      .step2-container {
        margin-top: 170px;
        padding-left: 110px;

        @include media('<xl') {
          margin: 0;
          padding: 0;
        }

        @include media('<iPad') {
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        h1 {
          margin: 17px 0 5px;
          font-family: $font-family-base;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 49px;
          color: $text-color;
  
          @include media('<iPad') {
            text-align: center;
          }

          @include media('<sm') {
            font-size: 30px;
          }
        }
        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 32px;
          color: $text-color;
          max-width: 400px;
          width: 100%;
  
          @include media('<iPad') {
            text-align: center;
          }

          @include media('<sm') {
            width: 302px;
          }
        }

        .btn {
          margin-top: 55px;
          width: 264px;
        }
      } 
    }
    .right-block {
      width: 56%;
      padding: 156px 97px 0 0;
  
      @include media('<lg') {
        width: 100%;
        padding: 140px 40px 0;
      }

      @include media('<iPad') {
        max-width: 500px;
        background-color: #ffffff;
        height: auto;
      }
      
      .image {
        max-width: 695px;
        width: 100%;
        height: auto;
      }
    }
  }
}