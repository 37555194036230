.continue-wrapper {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  .continue-header {
    padding: 70px 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .logo {
      width: 108px;
      height: 32px;
      background-color: blueviolet;
    }

    @include media('<xl') {
      padding: 50px 40px 0;
    }

    @include media('<sm') {
      padding: 36px 22px 0;
    }
  }
  
  .continue-container {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media('<iPad') {
      padding: 0px 40px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .left-block {
      width: 58%;
      padding: 200px 0 0 170px;

      @include media('<lg') {
        max-width: 450px;
        width: 100%;
        padding: 157px 0 0 125px;
      }

      @include media('<iPad') {
        max-width: 400px;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: $dark;

        @include media('<iPad') {
          text-align: center;
          margin-top: 24px;
        }

        @include media('<sm') {
          font-size: 30px;
          line-height: 34px;
        }
      }

      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #263238;
        margin: 10px 0 70px;
        max-width: 573px;
        width: 100%;

        @include media('<lg') {
          margin: 10px 0 25px;
        }

        @include media('<iPad') {
          text-align: center;
          margin: 10px 0 35px;
        }

        @include media('<sm') {
          margin: 10px 0 70px;
        }
      }

      @include media('<lg') {
        .btn {
          width: 293px;
        }
      }
    }
    .right-block {
      width: 42%;
      padding: 68px 157px 0 0;

      @include media('<lg') {
        max-width: 515px;
        width: 100%;
        padding: 128px 60px 0 0;
      }

      @include media('<iPad') {
        max-width: 500px;
        width: 100%;
        padding: 51px 0 0;
      }

      @include media('<sm') {
        max-width: 230px;
      }

      .image {
        max-width: 459px;
        width: 100%;
        height: auto;
      }
    }
  }
}