.react-auto-complete-email {
  width: fit-content;
  height: fit-content;
  margin: auto;
  padding: 30px;
  padding-top: 40px;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  margin-top: 300px;

  input {
    padding: 14px;
    margin-bottom: 14px;
    width: 200px;
    border-radius: 10px;
    background: rgba(204,204,204,0.4);
    border: none;
    transition-duration: .2s;
    font-size: 16px;

    &:focus {
      border: 1px solid cornflowerblue;
      outline: none;
      transform: scale(1.1);
    }
  }

  button {
    padding: 10px;
    width: 100px;
    background: rgba(204,204,204,0.2);
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    border: none;
    transition-duration: .2s;
    outline: none;

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
      border: 1px solid cornflowerblue;
      transform: scale(1.1);
    }
  }
}

/* Autocomplete email css */
.auto-complete-container {
  position: relative;

  .auto-complete-overlay {
    border-radius: 2px;
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 100%;
    /* background: coral; */
    background-color: #FCFCFC;
    border: 1px solid hsl(0, 0%, 93%);
  }
}
.auto-complete-overlay {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 12px 15px 12px 15px;
    //cursor: pointer;
    border-bottom: 1px solid #EDEDED;
    will-change: background-color;
    transition: background-color .2s ease-in-out;

    &.selected {
      background-color: #F3F3F3;
    }

    &:last-child {
      border: none;
    }
  }

  span {
    &.completion {
      color: #0787ad;
    }
  }
}

.container-email-autofill {
  .auto-complete-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 170px;
    height: 100%;

    @include media('<xl') {
      right: 85px;
    }
  }

  .auto-complete-overlay {
    background-color: transparent;
    border: none;
    top: 0;
    height: 100%;
    z-index: 0;
    position: static;

    ul {
      position: relative;
      height: 100%;
      overflow-x: hidden;
    }

    li {
      color: transparent;
      padding: 0 20px;
      font-size: 20px;
      line-height: 50px;
      margin-bottom: 0;

      @include media('<md') {
        font-size: 18px;
      }
    }

    span {
      &.completion {
        color: #aaa;
        position: absolute;
        z-index: 2;
      }
    }
  }

  .field {
    &__input {
      padding-right: 170px;

      @include media('<xl') {
        padding-right: 85px;
      }
    }
  }

  &--filled {
    .field {
      &__input {
        padding-right: 20px;

        @include media('<xl') {
          padding-right: 20px;
        }
      }
    }
  }
}