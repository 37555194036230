.artist-item {
  $this: &;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $gray-light;
  border-radius: 4px;
  padding: 3px 23px 3px 3px;
  margin-bottom: 24px;
  min-height: 50px;
  box-sizing: border-box;

  &__image-container {
    width: 42px;
    height: 42px;
    position: relative;
    margin-right: 8px;
    border-radius: 2px;
    overflow: hidden;

    &--empty {
      width: 12px;
    }
  }
  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    color: $black;
  }
  &__icon-container {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--checked {
    border: 2px solid $brand-accent;
    padding: 2px 22px 2px 2px;

    #{$this}__title {
      color: $brand-accent;
      font-weight: 600;
    }
  }

  &--disabled {
    border: 2px solid #9E9E9E;
    padding: 2px 22px 2px 2px;

    #{$this}__title {
      color: #9E9E9E;
      font-weight: 600;
    }
  }
}