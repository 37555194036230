.preloader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  transition: background-color ease 0.15s;
  pointer-events: none;
  z-index: 100;

  &--flat {
    background-color: rgba(255, 255, 255, 1);
  }
}

.preloader-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1em;
  transform: translate(-50%, -50%);
  animation-delay: -0.16s;
  display: flex;
  justify-content: center;
  align-items: center;

  &--small {
    .preloader-spinner-dot {
      width: 0.5em;
      height: 0.5em;
      margin: 0 0.20em;
    }
  }
}

.preloader-spinner-dot {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: animation-preloader-spinner 1.8s infinite ease-in-out;
  margin: 0 0.75em;

  &--dot-1 {
    animation-delay: -0.4s;
  }
  &--dot-2 {
    animation-delay: -0.2s;
  }
  &--dot-4 {
    animation-delay: 0.2s;
  }
  &--dot-5 {
    animation-delay: 0.4s;
  }
}

@keyframes animation-preloader-spinner {
  0% {
    box-shadow: 0 1em 0 -0.5em #B9A0FF;
  }
  25% {
    color: 0 1em 0 -0.2em #8E66FF;
  }
  40% {
    box-shadow: 0 1em 0 0 #8E66FF;
  }
  50% {
    color: 0 1em 0 -0.12em #6C3CF4;
  }
  75% {
    color: 0 1em 0 -0.44em #8825EE;
  }
  80% {
    box-shadow: 0 1em 0 -0.5em #8825EE;
  }
  100% {
    box-shadow: 0 1em 0 -0.5em #7C12E8;
  }
}

@keyframes animation-color-spinner-loader {
  0% {
    color: #B9A0FF;
  }
  25% {
    color: #8E66FF;
  }
  50% {
    color: #6C3CF4;
  }
  75% {
    color: #8825EE;
  }
  100% {
    color: #7C12E8;
  }
}
