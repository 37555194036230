.tabs {
  position: relative;

  &__nav {}
}

.tabs-nav {
  margin-bottom: 24px;
  position: relative;

  &__row {
    margin: 0 -4px;
  }
  &__col {
    padding: 0 4px;
  }
}

.tabs-body {
  position: relative;
}
.tab-item {
  position: relative;
  display: none;

  &--open {
    display: block;
  }
}