.progressbar-wrapper {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: space-between;

  &--large {
    max-width: 326px;
  }

  &--medium {
    max-width: 243px;
  }

  @include media('<md') {
    max-width: 270px;
  }

  @include media('<sm') {
    max-width: 157px;
    margin-bottom: 10px;
  }

  &--small {
    max-width: 160px;
  }

  .bar-element {
    max-width: 78px;
    width: 100%;
    height: 10px;
    background: $light-accent;
    border-radius: 4px;
    margin: 0 2px;

    &--highlight {
      background: $brand-accent;
    }
  }
}