.playlist {
  position: relative;

  &__header {}
  &__header-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    @include media('<md') {
      margin-bottom: 10px;
    }
  }
  &__header-left {}
  &__header-right {}
  &__title {}

  &__details {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media('<md') {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &__header-separator {
    width: 6px;
    height: 6px;
    border-radius: 1px;
    background-color: $text-color;
    margin: 0 14px;

    @include media('<md') {
      margin: 0 8px;
    }
  }
  &__refresh-button {
    @include media('<md') {
      border: none;
      padding: 6px;
      width: auto;
    }
  }
}

.manage-songs {
  position: relative;
}
.songs-filter {
  position: absolute;
  right: 24px;
  margin-top: -66px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include media('<lg') {
    display: none;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #939393;
    margin-right: 10px;
  }
  &__button {
    border: 1px solid $black;
    border-radius: 30px;
    padding: 10px 16px;
    box-sizing: border-box;
    width: 171px;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__button-icon {
    font-size: 11px;
  }
}