.forgotPassword-wrapper {
  width: 100%;
  .step1-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    .logo-wrapper {
      padding: 70px 0 0 60px;

      @include media('<xl') {
        padding: 50px 0 0 40px;
      }
      @include media('<sm') {
        padding: 36px 22px 0;
      }
    }
    .forgotPassword-container {
      max-width: 869px;
      width: 100%;
      margin: 0 auto;
      position: relative;

      @include media('<xl') {
        padding: 0px 40px;
      }

      @include media('<sm') {
        padding: 0px 22px;
      }

      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: #1D1D1D;
        margin-top: 57px;

        @include media('<sm') {
          font-size: 30px;
          margin-top: 46px;
        }
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #263238;
        margin-top: 10px;

        @include media('<sm') {
          font-size: 16px;
          margin-top: 0px;
        }
      }
      .input-wrapper {
        margin-top: 70px;
        max-width: 446px;
        width: 100%;
      }
      .error-block {
        position: absolute;
        top: 235px;

        @include media('<xl') {
          top: 265px;
        }

        @media (max-width: 420px) {
          top: 295px;
          padding-right: 22px;
        }

        @media (max-width: 356px) {
          top: 345px;
        }

        .error-message {
          color: red;
          font-size: 16px;
          line-height: 19px;
        }

        p {
          .purple {
            color: $brand-accent;
          }
        }
      }
      .buttons-wrapper {
        margin-top: 109px;
        max-width: 469px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media('<md') {
          align-items: center;
          justify-content: center;
          max-width: 100%;
          flex-direction: column;
          .btn {
            max-width: 330px;
            width: 100%;
          }
        }

        @include media('<md') {
          margin-top: 200px;
        }
      }
    }
  }
  .step2-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .congratulations-header {
      max-width: 1440px;
      width: 100%;
      position: absolute;
      padding: 70px 0 0 60px;

      @include media('<xl') {
        padding: 50px 0 0 40px;
      }
      @include media('<sm') {
        padding: 36px 22px 0;
      }
    }
    .congratulations-container {
      display: flex;
      width: 100%;
      flex-grow: 1;

      @include media('<iPad') {
        width: 100%;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
      }
      .left-block {
        width: 44%;
        padding: 70px 60px;
        display: flex;
        justify-content: flex-end;

        @include media('<lg') {
          max-width: 450px;
          width: 100%;
          padding: 239px 0 0 125px;
        }

        @include media('<iPad') {
          padding: 0;
          align-items: center;
          flex-direction: column;
        }

        .step2-container {
          margin-top: 220px;
          padding-left: 110px;

          @include media('<xl') {
            margin: 0;
            padding: 0;
          }

          @include media('<iPad') {
            display: flex;
            align-items: center;
            flex-direction: column;
          }

          h1 {
            margin: 0 0 15px;
            font-family: $font-family-base;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 49px;
            color: $text-color;

            @include media('<iPad') {
              text-align: center;
            }
            @include media('<sm') {
              font-size: 30px;
              line-height: 37px;
            }
          }
          p {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            color: $text-color;
            max-width: 400px;
            width: 100%;

            @include media('<iPad') {
              text-align: center;
            }
            @include media('<sm') {
              font-size: 16px;
            }
          }
          .login-here-btn {
            margin-top: 30px;
            max-width: 229px;
            width: 100%;
          }
        } 
      }
      .right-block {
        width: 56%;
        background-color: #F4F4F4;
        padding: 160px 120px 0;
        height: 100vh;

        &--good-news {
          background-color: #ffffff;
        }
        @include media('<lg') {
          width: 100%;
          padding: 150px 40px 0;
        }
        @include media('<iPad') {
          max-width: 500px;
          background-color: #ffffff;
          height: auto;
        }
        .image {
          max-width: 695px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}