.slider-container {
  position: relative;
  background-color: $light;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 11px 0;
}
.slick-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slick-list {
  overflow-x: hidden;
}
.slick-track {
  display: flex;
}
.slider-arrow {
  width: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  &__next {}
  &__prev {
    transform: rotate(180deg);
  }
}

.videos-carousel {

  @include media('<md') {
    position: relative;
    background-color: $light;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 18px;
  }
}