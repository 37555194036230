.active-plan {
  border: 1px solid $gray-border;
  border-radius: 10px;
  padding: 20px 27px 32px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &__title {
    color: #8A8A8A;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 600;

    @include media('<md') {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 17px;
    }
  }
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<md') {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  &__icon {
    margin-right: 12px;
  }
  &__left {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 10px;

    &--pro {
      flex-basis: 33%;
    }

    @include media('<md') {
      padding-right: 0;
    }
  }
  &__center {
    &--pro {
      font-family: $font-family-base;
      flex-basis: 33%;
      flex-grow: 0;
      flex-shrink: 0;
      box-sizing: border-box;


      @include media('<md') {
        order: 3;
      }
    }
  }
  &__right {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    &--pro {
      flex-basis: 33%;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: #7A23D3;

      @include media('<md') {
        margin-bottom: 27px;
      }
    }

    @include media('<md') {
      align-items: flex-start;
      padding-left: 0;
    }
  }
  &__info {
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
  }
  &__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 7px;

    @include media('<md') {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 14px;
    }
  }
  &__notification {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    padding: 3px 13px;
    border-radius: 14px;
    border: 1px solid $black;
    display: inline-block;
  }
  &__price {
    font-size: 45px;
    line-height: 54px;
    font-weight: 700;
    color: $brand-accent;

    @include media('<md') {
      font-size: 35px;
      line-height: 43px;
    }
  }
  &__price-annotation {
    font-size: 16px;
    line-height: 20px;
    color: $black;

    @include media('<md') {
      font-size: 14px;
      line-height: 32px;
      margin-bottom: 11px;
    }
  }
}

.payment-footer-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding-top: 20px;

  &__item {
    $this: &;
    padding: 5px 20px 5px 24px;
    margin: 0;
    font-size: 16px;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -2px;
      width: 4px;
      height: 4px;
      border-radius: 1px;
      background-color: $brand-accent;

      @include media('<md') {
        display: none;
      }
    }

    &:first-child {
      padding-left: 20px;

      &::before {
        display: none;
      }
    }

    @include media('<md') {
      padding: 3px 10px;
      text-align: center;
      font-size: 13px;

      &:first-child {
        padding-left: 10px;
      }
    }
  }

  @include media('<md') {
    display: block;
  }
}