.take-photo {

  &__video-container {
    position: relative;
    margin-bottom: 25px;
  }
  &__video {
    width: 100%;
    height: auto;
    max-height: 470px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  &__image {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
}