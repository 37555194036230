.profileCreation-wrapper {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  .profileCreation-header {
    padding: 70px 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .logo {
      width: 108px;
      height: 32px;
      background-color: blueviolet;
    }

    @include media('<xl') {
      padding: 50px 40px 0;
    }

    @include media('<sm') {
      padding: 36px 22px 0;
    }
  }
  
  .profileCreationStep1-container {
    max-width: 869px;
    width: 100%;
    margin: 104px auto 0;

    @include media('<xl') {
      padding: 0px 40px;
    }
    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }
    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;

      @include media('<sm') {
        font-size: 30px;
        width: 225px;
      }
    }
    .input-wrapper {
      max-width: 446px;
      width: 100%;
      margin: 15px 0;

      &--pro {
        margin-top: 45px;
      }

      @include media('<sm') {
        margin-top: 26px;
      }
      .field {
        padding-bottom: 5px;
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;

        @include media('<sm') {
          margin-bottom: 10px;
          font-size: 18px;
        }
      }
    }
    p {
      margin: 5px 0;
      max-width: 768px;
      width: 100%;
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      .notify-me {
        color: $brand-accent;
        text-decoration: none;
        cursor: pointer;
      }

      @include media('<sm') {
        font-size: 16px;
      }
    }
    .next-btn {
      margin-top: 45px;

      @include media('<md') {
        width: 176px;
      }
    }
  }

  .profileCreationStep2-container {
    max-width: 869px;
    width: 100%;
    margin: 74px auto 0;

    @include media('<xl') {
      padding: 0px 40px;
    }
    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }
    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;

      @include media('<sm') {
        font-size: 30px;
        line-height: 34px;
      }
    }
    p {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #263238;

      &.head-paragraph {
        margin-bottom: 35px;
      }

      @include media('<sm') {
        font-size: 16px;
        margin: 0;
      }
    }

    .radio-wrapper {

      @include media('<xl') {
        margin-bottom: 20px;
      }
      
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;
        margin: 0;
      }
      .radio-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 831px;
        max-width: 100%;

        &__cognitive-decline-level {
          width: 616px;
          margin-top: 17px;
        }

        @media (max-width: 866px) {
          width: 450px;
        }

        @include media('<sm') {
          width: 331px;
        }

        @media (max-width: 356px) {
          flex-direction: column;
          align-items: center;
        }

        .radio-btn {
          max-width: 190px;
          width: 100%;
          padding: 0;

          @include media('<sm') {
            max-width: 158px;
          }
        }
        .prefer-not-to-say {
          .radio-button {
            padding: 0;
          }
        }
      }
    }
    .input-wrapper {
      max-width: 446px;
      width: 100%;

      &--pro {
        margin-top: 80px;
      }

      p {
        font-weight: bold;
      }
      
      &--year-picker {
        .field {
          &__label {
            margin-bottom: 17px;
          }
        }
      }
    }
    .buttons-wrapper {
      max-width: 367px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
      padding-bottom: 35px;

      &--pro {
        margin-top: 90px;
      }

      @include media('<md') {
        .btn {
          width: 158px;
        }
      }
      
      @include media('<sm') {
        margin-top: 10px;
      }
    }
  }
  .cognitive-decline-title-wrapper {
    display: flex;
    align-items: center;
  }

  .profileCreationStep3-container {
    max-width: 869px;
    width: 100%;
    margin: 74px auto 0;

    &--pro {
      margin-top: 32px;
    }

    @include media('<xl') {
      padding: 0px 40px;
    }

    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }

    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;
      
      @include media('<sm') {
        font-size: 30px;
        line-height: 36px;
      }
    }
    p {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #263238;
      margin-bottom: 45px;

      @include media('<sm') {
        font-size: 16px;
        margin-bottom: 30px;
        line-height: 22px;
      }
    }

    p.paragraph-pro {
      margin-bottom: 30px;
    }

    .input-wrapper {
      max-width: 591px;
      width: 100%;

      &--pro {
        max-width: 712px;
      }

      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;
        margin: 0;

        @include media('<sm') {
          font-size: 18px;
        }
      }
      .field-select {
        max-width: 446px;
        width: 100%;

        &--pro {
          max-width: 712px;

          .react-select-container {
            max-width: 712px;
          }
        }
      }

      @include media('<sm') {
        margin-bottom: 15px;
      }
    }

    .city-post-code {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .city {
        flex-grow: 10;
        margin-right: 24px;

        @include media('<sm') {
          margin-right: 0;
        }
      }
      .post-code {
        flex-grow: 1;
      }
    }
    .buttons-wrapper {
      margin-top: 13px;
      max-width: 367px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include media('<md') {
        .btn {
          width: 156px;
        }
      }

      @include media('<sm') {
        margin-top: 30px;
      }
    }
  }

  .profileCreationStep4-container {
    max-width: 869px;
    width: 100%;
    margin: 74px auto 0;

    @include media('<xl') {
      padding: 0 0 0 40px;
    }

    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }

    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;

      @include media('<sm') {
        font-size: 30px;
        line-height: 36px;
      }
    }
    p {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #263238;
      margin-bottom: 45px;

      @include media('<sm') {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .checkboxes-wrapper {
      max-width: 867px;
      width: 100%;
      .row {
        display: flex;
        width: inherit;

        @include media('<sm') {
          margin: 0;
          justify-content: space-between;
        }
        .col-auto {
          max-width: 198px;
          width: 100%;

          @include media('<sm') {
            max-width: 158px;
            padding: 0;
          }

          @media (max-width: 369px) {
            max-width: 135px;
          }
          .field {
            padding-bottom: 20px;

            @include media('<sm') {
              padding-bottom: 10px;
            }
          }
        }
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;
        margin-bottom: 10px;

        @include media('<sm') {
          font-size: 18px;
        }
      }
    }
    .buttons-wrapper {
      margin-top: 34px;
      max-width: 367px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include media('<md') {
        .btn {
          width: 156px;
        }
      }

      @include media('<sm') {
        margin-top: 30px;
      }

      @media (max-width: 369px) {
        .btn {
          width: 135px;
        }
      }
    }
  }

  .profileCreationStep5-container {
    max-width: 869px;
    width: 100%;
    margin: 104px auto 0;

    @include media('<xl') {
      padding: 0px 40px;
    }

    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }

    .select-wrapper {
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;
      }
    }
    .checkbox-wrapper {
      max-width: 870px;
      width: 100%;
      padding: 37px 26px;
      background: #F8F6FA;
      border-radius: 4px;
      position: relative;
      div {
        background: #FFFFFF;
        border: 1px solid #CFCFCF;
        box-sizing: border-box;
        border-radius: 4px;
        width: 200px;
        height: 50px;
      }
    }
    .buttons-wrapper {
      margin-top: 48px;
      max-width: 367px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include media('<md') {
        .btn {
          max-width: 156px;
          width: 100%;
        }
      }
    }
  }

  .profileCreationStep6-container {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    @include media('<iPad') {
      padding: 0px 40px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .left-block {
      width: 53%;
      padding: 220px 0 0 170px;

      &--listener {
        width: 45%;
      }

      @include media('<lg') {
        max-width: 450px;
        width: 100%;
        padding: 157px 0 0 125px;
      }

      @include media('<iPad') {
        max-width: 400px;
        width: 100%;
        padding: 40px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: $dark;
        margin-bottom: 70px;

        @include media('<lg') {
          font-size: 34px;
          line-height: 40px;
        }

        @include media('<iPad') {
          text-align: center;
        }

        @include media('<sm') {
          font-size: 30px;
          line-height: 34px;
        }
      }

      h1.header-pro {
        margin-bottom: 10px;
      }

      .step6-title {
        margin: 0;

        @include media('<lg') {
          margin-bottom: 30px;
        }

        @include media('<iPad') {
          max-width: 400px;
        }
      }

      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #263238;
        margin: 10px 0 70px;

        &.p-logout {
          width: 400px;
        }

        &.step6-text {
          width: 100%;
          margin: 20px 0 70px;
        }

        @include media('<lg') {
          margin: 10px 0 25px;
        }

        @include media('<iPad') {
          text-align: center;
          margin: 10px 0 35px;
        }

        @include media('<sm') {
          margin: 10px 0 70px;
        }
      }

      p.paragraph-pro {
        margin: 0 0 60px;
      }

      .btn {
        width: 293px;
        margin-top: 40px;

        @include media('<lg') {
          margin-top: 22px;
        }
      }
    }
    .right-block {
      width: 47%;
      padding: 130px 90px 0;

      &--pro{
        padding: 137px 59px 0 0;
      }

      @include media('<lg') {
        max-width: 515px;
        width: 100%;
        padding: 128px 60px 0 0;
      }

      @include media('<iPad') {
        max-width: 500px;
        width: 100%;
        padding: 103px 0 0;
      }
      .image {
        max-width: 695px;
        width: 100%;
        height: auto;

        &--pro{
          max-width: 100%;
        }
      }

      &--listener {
        margin-top: 130px;
        padding: 0;
        width: 55%;

        @include media('<xl') {
          width: 100%;
        }

        .vera-video {

          div {
            max-width: 670px;
            width: 100%;
            height: 377px;
            
            @media (max-width: 1300px) {
              max-width: 490px;
              height: 275px;
            }
            @include media('<sm') {
              height: 170px;
            }
          }
        }
      }
    }
  }

  .profileCreationStep7-container {
    max-width: 1096px;
    width: 100%;
    margin: 0 auto;
    padding: 89px 20px 20px;

    .purple-underlined {
      font-family: $font-family-base;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      text-decoration: underline;
      color: $brand-accent;
    }

    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: #000000;

      @include media('<sm') {
        font-size: 32px;
      }
    }
    p {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 32px;
      color: #000000;
      margin-top: 10px;
    }
    .radio-wrapper {
      margin-top: 32px;
    }
    .input-wrapper {
      display: flex;
      max-width: 860px;
      align-items: center;
      flex-wrap: wrap;
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: #000000;
        margin: 0 15px 0 0;
      }
      .field {
        padding: 0;
        width: 282px;
        margin: 0 15px 0 0;
      }
      .error-message {
        font-size: 16px;
        color: red;
      }
    }
    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 82px;
      flex-wrap: wrap;

      &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 376px;
        flex-grow: 0;
        flex-shrink: 0;

        @include media('<md') {
          width: 100%;
          display: block;
        }
      }
      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 3px 25px 3px 0;
        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 25px;
          color: #000000;
          margin-top: 0;
  
          @include media('<md') {
            width: 100%;
          }
        }
        .links-wrapper {
          max-width: 270px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .point {
            width: 3px;
            height: 3px;
            background-color: $brand-accent;
          }
          a {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: $brand-accent;
            text-decoration: none;
          }
        }
      }
    }
  }

  .notifyMe-container {
    max-width: 869px;
    width: 100%;
    margin: 104px auto 0;

    @include media('<xl') {
      padding: 0px 40px;
    }

    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }
    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;

      @include media('<sm') {
        font-size: 30px;
      }
    }
    .input-wrapper {
      margin-top: 30px;
      max-width: 446px;
      width: 100%;
      .react-select__menu {
        z-index: 999;
      }
      .field {
        padding-bottom: 5px;
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;
        margin: 0 0 5px;
      }
    }
    .buttons-wrapper {
      margin-top: 55px;
      max-width: 370px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .cancel-btn {
        min-width: 0;
        max-width: 146px;
        width: 100%;
      }

      .notify-btn {
        max-width: 176px;
        width: 100%;
      }
    }
  }

  .paymentCancelled-container {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media('<iPad') {
      padding: 0px 40px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .left-block {
      width: 52%;
      padding: 200px 0 0 170px;

      @include media('<lg') {
        max-width: 500px;
        width: 100%;
        padding: 167px 0 0 74px;
      }

      @include media('<iPad') {
        max-width: 400px;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: $dark;

        @include media('<iPad') {
          text-align: center;
        }

        @include media('<sm') {
          font-size: 30px;
          line-height: 34px;
        }
      }

      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #263238;
        margin: 0 0 45px;
        
        @include media('<lg') {
          margin: 10px 0 25px;
        }

        @include media('<iPad') {
          text-align: center;
          margin: 10px 0 35px;
        }

        @include media('<sm') {
          margin: 10px 0 10px;
        }
      }

      @include media('<lg') {
        .btn {
          width: 253px;
        }
      }
    }
    .right-block {
      width: 48%;
      padding-left: 20px;
      margin-top: 183px;

      @include media('<lg') {
        max-width: 515px;
        width: 100%;
      }

      @include media('<iPad') {
        max-width: 500px;
        width: 100%;
        margin-top: 50px;
        padding: 10px 0;
      }
      .image {
        max-width: 555px;
        width: 100%;
        height: auto;
      }
    }
  }

  .profileCreationPaymentSuccess-container {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    @include media('<iPad') {
      padding: 0px 40px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .left-block {
      width: 45%;
      padding: 220px 0 0 170px;

      @include media('<lg') {
        max-width: 450px;
        width: 100%;
        padding: 157px 0 0 125px;
      }

      @include media('<iPad') {
        max-width: 400px;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: $dark;

        @include media('<lg') {
          font-size: 34px;
          line-height: 40px;
        }

        @include media('<iPad') {
          text-align: center;
        }

        @include media('<sm') {
          font-size: 30px;
          line-height: 34px;
        }
      }

      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #263238;
        margin: 5px 0 25px;

        @include media('<lg') {
          margin: 10px 0 25px;
        }

        @include media('<iPad') {
          text-align: center;
          margin: 10px 0 35px;
        }
      }
    }
    .right-block {
      width: 55%;
      margin-top: 125px;

      @include media('<lg') {
        max-width: 515px;
        width: 100%;
      }

      @include media('<iPad') {
        max-width: 500px;
        width: 100%;
      }
      .image {
        max-width: 723px;
        width: 100%;
        height: auto;
      }
    }
  }

  .profileCreationGuestAdmin1-container {
    max-width: 869px;
    width: 100%;
    margin: 104px auto 0;

    @include media('<xl') {
      padding: 0px 40px;
    }
    @include media('<sm') {
      margin: 0;
      padding: 46px 22px 0;
    }
    h1 {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      color: $dark;
      margin-bottom: 35px;

      @include media('<sm') {
        font-size: 30px;
      }
    }

    .input-wrapper {
      max-width: 446px;
      width: 100%;

      @include media('<sm') {
        margin-top: 26px;
      }
      .field {
        padding-bottom: 5px;
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #263238;

        @include media('<sm') {
          margin-bottom: 10px;
          font-size: 18px;
        }
      }
    }
    p {
      margin: 5px 0;
      max-width: 768px;
      width: 100%;
      font-family: $font-family-base;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      .notify-me {
        color: $brand-accent;
        text-decoration: none;
        cursor: pointer;
      }

      @include media('<sm') {
        font-size: 16px;
      }
    }
    .buttons-wrapper {
      max-width: 367px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 110px;

      @include media('<md') {
        .btn {
          width: 158px;
        }
      }
      
      @include media('<sm') {
        margin-top: 328px;
      }
    }
  }

  .profileCreationGuestAdmin2-container {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    @include media('<iPad') {
      padding: 0px 40px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .left-block {
      width: 53%;
      padding: 200px 0 0 170px;

      @include media('<lg') {
        max-width: 450px;
        width: 100%;
        padding: 157px 0 0 125px;
      }

      @include media('<iPad') {
        max-width: 400px;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        color: $dark;
        margin-bottom: 70px;

        @include media('<lg') {
          font-size: 34px;
          line-height: 40px;
        }

        @include media('<iPad') {
          text-align: center;
        }

        @include media('<sm') {
          font-size: 30px;
          line-height: 34px;
        }
      }

      .step6-title {
        margin: 0;

        @include media('<lg') {
          max-width: 179px;
          margin-bottom: 30px;
        }

        @include media('<iPad') {
          max-width: 400px;
        }
      }

      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #263238;
        margin: 10px 0 70px;

        &.p-logout {
          width: 400px;
        }

        &.step6-text {
          width: 100%;
          margin: 20px 0 70px;
        }

        @include media('<lg') {
          margin: 10px 0 25px;
        }

        @include media('<iPad') {
          text-align: center;
          margin: 10px 0 35px;
        }

        @include media('<sm') {
          margin: 10px 0 70px;
        }
      }
      
      .btn {
        width: 253px;
      }
    }
    .right-block {
      width: 47%;
      padding: 130px 90px 0;

      @include media('<lg') {
        max-width: 515px;
        width: 100%;
        padding: 128px 60px 0 0;
      }

      @include media('<iPad') {
        max-width: 500px;
        width: 100%;
        padding: 103px 0 0;
      }
      .image {
        max-width: 695px;
        width: 100%;
        height: auto;
      }
    }
  }

  .btn-close-chat-mobile {
    height: 61px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}