.login-wrapper {
  display: flex;
  width: 100%;
  flex-grow: 1;
  max-width: 1440px;

  @include media('<sm') {
    padding: 0px 22px;
  }
  @media (max-width: 946px) {
    display: flex;
    justify-content: center;
  }

  .left-block {
    padding: 60px 70px 30px;
    width: auto;
    background-color: white;
    display: flex;
    justify-content: flex-end;


    @include media('<md-lg') {
      padding: 50px 0 0;
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 1360px) {
      padding: 50px 40px 30px;
    }

    @include media('<sm') {
      padding: 25px 0;
      width: 100%;
    }

    .login-form-container {
      width: 441px;
      position: relative;

      @include media('<sm') {
        max-width: 441px;
        width: 100%;
      }
      h1 {
        margin-top: 17px;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: $text-color;

        @include media('<sm') {
          font-size: 30px;
        }
      }
      .join-now-block {
        display: flex;
        margin: 7px 0 32px 0;
        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $text-color;
        }
        .join-now {
          text-decoration: none;
          font-family: $font-family-base;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: $brand-accent;
          margin-left: 4px;
          cursor: pointer;

          @include media('<sm') {
            margin-left: 20px;
          }
        }
      }
      .field {
        padding-bottom: 20px;
        &__label {
          font-size: 18px;
          font-weight: normal;
        }
        &__input {
          height: 45px;
          font-size: 18px;
        }
      }
      .error-message {
        font-size: 16px;
        color: red;
        position: absolute;
        top: 155px;
      }
      .input-email {
        padding-bottom: 35px;
      }
      .input-password {
        @include media('<md') {
          padding-bottom: 22px;
        }
      }
      .login-buttons {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        
        @include media('<md') {
          .btn {
            width: 176px;
          }
        }
        @media (max-width: 946px) {
          display: none;
        }

        .remember-me {
          padding-bottom: 0;
          .checkbox__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;

            @include media('<sm') {
              font-size: 14px;
            }
          }
        }
        .forgot-password {
          text-decoration: none;
          padding-top: 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 0;
          text-align: right;
          color: #7A23D3;

          @include media('<lg') {
            padding-top: 10px;
          }
        }
      }
      .login-button {
        width: 100%;
        height: 50px;
        font-size: 18px;
        line-height: 22px;
        margin: 56px 0 10px;
        letter-spacing: 0.1em;

        @media (max-width: 946px) {
          margin: 30px 0;
        }
      }
      .forgot-password-mobile {
        display: none;
        margin-bottom: 80px;

        @media (max-width: 946px) {
          display: flex;
          justify-content: center;
        }
        .forgot-password {
          font-weight: 600;
          font-size: 16px;
          text-decoration: none;
        }
      }
      .social-btn {
        min-width: 103px;
        width: 141px;

        @media (max-width: 480px) {
          width: 120px;
        }
        @media (max-width: 410px) {
          width: 103px;
        }
      }
      .switch-vera-type {
        text-align: center;
        margin-top: 40px;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        span {
          color: $brand-accent;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  .right-block {
    width: 100%;
    padding: 122px 70px 0 90px;
    background-color: white;

    @include media('<xl') {
      padding: 100px 47px 0 15px;
    }

    @media (max-width: 946px) {
      display: none;
    }

    @include media('<lg') {
      padding-left: 17px;
    }

    .text-wrapper {
      width: 100%;
      max-width: 581px;
      padding: 102px 0 127px 93px;
      background: #FBF8FF;
      border: 2px solid #D9BCFC;
      box-sizing: border-box;
      border-radius: 73px;
      position: relative;

      @include media('<xl') {
        max-width: 384px;
        padding: 91px 0 100px 73px;
      }

      @include media('<lg') {
        max-width: 398px;
      }

      h1 {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 29px;
        color: #263238;

        @include media('<xl') {
          font-size: 32px;
        }
      }
      p {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        max-width: 346px;
        font-size: 18px;
        line-height: 29px;
        color: #263238;
        margin: 0;

        @include media('<xl') {
          font-size: 18px;
        }
        @include media('<lg') {
          max-width: 246px;
        }
      }
    }

    .image {
      max-width: 380px;
      width: 100%;
      height: auto;
      position: absolute;
      margin-top: 30px;
      top: 40%;
      left: 62%;

      @include media('<lg') {
        max-width: 254px;
        top: 57%;
        left: 54%;
      }
    }
  }

  // fast update for errors
  .field__error {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 17px;
  }
}
.social-network-wrapper {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  .social-network {
    max-width: 154px;
    width: 100%;
    height: 56px;
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    &:nth-child(even) {
      margin: 0 10px;
    }
  }
}